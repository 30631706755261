/* ~~~~~~~~~~ About -> Our company ~~~~~~~~~~ */

.template-about-our-company {
    background: url('../images/pages/about/bgd_about-our-company-1920.jpg') top center no-repeat;
    padding-bottom: 150px;

    @media (max-width: 1199px) {
        background-image: url('../images/pages/about/bgd_about-our-company-1200.jpg');
        padding-bottom: 100px;
    }

    @media (max-width: 991px) {
        background-image: url('../images/pages/about/bgd_about-our-company-992.jpg');
        padding-bottom: 75px;
    }

    @media (max-width: 767px) {
        background: transparent;
        padding-bottom: 50px;
    }

    .slogan {
        margin-top: 225px;

        @media (max-width: 991px) {
            margin-top: 175px;
        }

        @media (max-width: 767px) {
            display: none;
        }

        span {
            display: block;
            @extend h2;
            @include bliss('light');
            color: $primary-color;
        }
    }

    .visible-xs {
        img {
            margin-top: 100px;
        }
    }

    .about-imm-technical {
        background: $primary-color;
        padding: 75px;
        margin-top: 75px;
        a {
            color: #272727;
            &:hover {
                border-bottom: 1px solid #272727;
            }
        }

        ul {
            list-style: disc;
        }
        ul, ol {
            color: #414042;
            margin-left: 30px;
        }

        @media (max-width: 1199px) {
            padding: 50px;
            margin-top: 50px;
        }

        @media (max-width: 991px) {
            padding: 35px;
            margin-top: 35px;
        }

        @media (max-width: 767px) {
            padding: 25px;
            margin-top: 25px;
        }

        h1 {
            span {
                color: #fff;
            }
        }
    }

    .director-statement {
        background-image: url('../images/pages/about/bgd_director-statement-1920.jpg');
        @extend .background-cover;
        height: auto;
        margin-top: 75px;
        overflow: visible;

        @media (max-width: 1199px) {
            margin-top: 50px;
            background-image: url('../images/pages/about/bgd_director-statement-1200.jpg');
        }

        @media (max-width: 991px) {
            margin-top: 35px;
            background-image: url('../images/pages/about/bgd_director-statement-992.jpg');
        }

        @media (max-width: 767px) {
            margin-top: 25px;
            background-image: url('../images/pages/about/bgd_director-statement-768.jpg');
        }

        .director-quote-wrapper {
            background: #fff;
            padding: 75px;
            position: relative;
            top: 75px;
            -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.33);
            -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.33);
            box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.33);

            @media (max-width: 1199px) {
                padding: 50px;
                top: 50px;
            }

            @media (max-width: 991px) {
                padding: 35px;
                top: 35px;
            }

            @media (max-width: 767px) {
                padding: 25px;
                top: 25px;
            }

            p {
                color: $primary-color;
            }

            a {
                color: #7E921A;
                &:hover {
                    border-bottom: 1px solid #7E921A;
                }
            }

            ul, ol {
                margin-left: 30px;
            }

            img {
                margin: 50px 0;

                @media (max-width: 991px) {
                    margin: 35px;
                }

                @media (max-width: 767px) {
                    margin: 25px 0;
                    max-width: 100%;
                }
            }
        }
    }
}