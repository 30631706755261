@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }

    .navbar-toggle {
        display: block;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);

        &.collapse {
            display: none!important;

            &.in {
                display: block!important;
            }
        }
    }

    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;

        > li {
            float: none;

            > a {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }

    .navbar-text {
        float: none;
        margin: 15px 0;
    }

    .collapsing {
        overflow: hidden!important;
    }
}