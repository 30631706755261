.navbar-wrapper {
    position: absolute;
    z-index: 10;
    @extend %absolute-center;

    .navbar-default {
        background: $secondary-color;
        border: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;

        @media (max-width: 767px) {
            border-radius: 0;
            margin: 0 -30px;
            padding: 0 15px;
        }

        .navbar-header {
            .navbar-toggle {
                border: none;
                border-radius: 0;
                margin: 60px 25px 0;

                @media (max-width: 767px) {
                    margin: 60px 10px 0;
                }

                &:hover,
                &:focus {
                    background: transparent;

                    .icon-bar {
                        background-color: $primary-color !important;
                        @extend %single-transition;
                    }
                }

                .icon-bar {
                    background-color: $primary-color;
                    @extend %single-transition;
                }

                &.collapsed {
                    .icon-bar {
                        background-color: #fff;
                        @extend %single-transition;
                    }
                }
            }

            .navbar-brand {
                height: 150px;
                padding: 50px 40px;

                @media (max-width: 1199px) {
                    padding: 70px 0 30px 40px;
                }

                @media (max-width: 991px) {
                    height: 120px;
                    padding: 50px 0 0 20px;
                }

                img {
                    width: 150px;
                    height: auto;
                    margin: 0;

                    @media (max-width: 1199px) {
                        width: 100px;
                    }
                }
            }
        }

        .navbar-collapse {
            border: none;
            margin-top: 20px;

            @media (max-width: 991px) {
                margin: 15px 15px 0 -15px;
                padding: 0;
            }

            @media (max-width: 767px) {
                margin: 0 -15px;
            }

            .navbar-nav {
                list-style: none;
                margin: 50px 30px 0 0;

                @media (max-width: 991px) {
                    margin: 5px 0;
                }

                li {
                    padding: 0;
                    text-indent: 0;

                    @media (max-width: 991px) {
                        margin: 0;
                        text-align: left;
                    }

                    & + li {
                        background: url('../images/imm-drop.png') no-repeat center left;
                        padding-left: 10px;

                        @media (max-width: 1199px) {
                            padding-left: 5px;
                        }

                        @media (max-width: 991px) {
                            background: transparent;
                            padding: 0;
                        }
                    }

                    a {
                        font-family: $font-stack;
                        font-size: 16px;
                        line-height: 21px;
                        color: #eee;
                        border: none;
                        font-weight: 300;
                    }

                    &.active a,
                    a:hover,
                    a:focus {
                        color: $primary-color;
                        background-color: transparent;
                        border: none;
                    }

                    &.open {
                        a,
                        a:focus,
                        a:hover {
                            background-color: transparent;
                        }
                    }

                    .caret {
                        margin-left: 10px;
                    }

                    .dropdown-menu {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        background-color: $secondary-color;
                        z-index: 2;

                        @media (max-width: 991px) {
                            position: static;
                            width: 100%;
                            border: 0 none;
                            box-shadow: none;
                            float: none;
                            background: #333;
                        }

                        li {
                            text-indent: 0;
                            background: transparent;
                            padding: 0;
                            margin: 0;

                            a {
                                color: #eeeeee;
                                padding: 10px 15px;
                                font-size: 14px;
                                line-height: 19px;
                            }

                            &.divider {
                                background: transparent;
                                background-color: #e5e5e5 !important;
                                opacity: 0.2;
                                padding: 0;
                            }

                            &.active a,
                            a:hover,
                            a:focus {
                                background-color: $primary-color;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }

        .navbar-contact {
            position: absolute;
            top: 0;
            right: 40px;

            @media (max-width: 991px) {
                right: 20px;
            }

            a {
                margin: 0;
                min-width: 200px;
                background-color: $primary-color;
                color: #fff;
                padding: 10px;
                font-size: 110%;
                border: 0;
                border-radius: 0 0 10px 10px;
                transition: 0.3s ease-out all;

                @media (max-width: 1199px) {
                    min-width: 180px;
                    padding: 5px;
                    font-size: 100%;
                }
                &:hover {
                    background-color: #7E921A;
                }
            }
        }
    }
}

