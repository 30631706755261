/* ~~~~~~~~~~ Repeaters ~~~~~~~~ */

%absolute-center {
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
}

%single-transition {
    transition: all 0.3s ease-out;
}

.vertical-center {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%); /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%); /* IE 9 */
    transform: translateY(-50%);
}

.background-cover {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}

.hide {
    text-indent: -998em;
    height: 0px;
}

.clearfix {
    clear: both;
}