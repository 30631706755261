/* ~~~~~~~~~~ WP admin bar ~~~~~~~~~~ */

.adminbar-fix {
    @media (min-width: 783px) {
        min-height: 32px;
    }

    @media (max-width: 782px) {
        min-height: 45px;
    }
}

#wpadminbar {
    @media (max-width: 600px) {
        position: fixed !important;
    }
}