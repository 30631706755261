/* ~~~~~~~~~~ About -> Testimonials ~~~~~~~~~~ */

.template-about-testimonials {
    overflow-x: hidden;

    .no-jumbotron {
        .relative-image-right {
            @media (max-width: 1199px) {
                margin-top: 0
            }
        }
    }

    .testimonials-list {
        margin-top: 125px;

        @media (max-width: 1199px) {
            margin-top: 100px;
        }

        @media (max-width: 991px) {
            margin-top: 35px;
        }

        .testimonial {
            background: #f7f9ee;
            position: relative;
            padding: 25px;
            margin-top: 50px;

            @media (max-width: 991px) {
                margin-top: 35px;
            }

            @media (max-width: 767px) {
                margin-top: 25px;
            }

            &:first-child {
                margin-top: 0;
            }

            &:nth-child(odd):before,
            &:nth-child(even):after {
                    position: absolute;
                    background: #f7f9ee;
                    top: 0;
                    bottom: 0;
                    width: 998em;
                    content: '';
                    display: block;
            }

            &:nth-child(odd):before {
                left: -998em;
            }

            &:nth-child(even):after {
                right: -998em;
            }

            img {
                margin-top: 0;

                @media (max-width: 767px) {
                    width: 85px;
                    margin: 0 auto;
                }
            }

            p {
                @include bliss('medium');

                &:first-child {
                    @media (max-width: 991px) {
                        margin-top: 0;
                    }

                    @media (max-width: 767px) {
                        margin-top: 25px;
                    }
                }
            }

            .position,
            .name {
                display: block;
                color: $primary-color;
                @extend p;
                margin: 0;
            }

            .position {
                margin-top: 25px;
            }
        }
    }
}