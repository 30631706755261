/* ~~~~~~~~~~ Fonts ~~~~~~~~~~ */

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../fonts/glyphicons-halflings-regular.eot');
    src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/glyphicons-halflings-regular.woff') format('woff'),
         url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'),
         url('../fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg');
}


@font-face {
    font-family: 'bliss-light';
    src: url('../fonts/bliss-light/bliss-light.eot');
    src: url('../fonts/bliss-light/bliss-light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bliss-light/bliss-light.woff2') format('woff2'),
         url('../fonts/bliss-light/bliss-light.woff') format('woff'),
         url('../fonts/bliss-light/bliss-light.ttf') format('truetype'),
         url('../fonts/bliss-light/bliss-light.svg#blissregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'bliss-medium';
    src: url('../fonts/bliss-medium/bliss-medium.eot');
    src: url('../fonts/bliss-medium/bliss-medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bliss-medium/bliss-medium.woff2') format('woff2'),
         url('../fonts/bliss-medium/bliss-medium.woff') format('woff'),
         url('../fonts/bliss-medium/bliss-medium.ttf') format('truetype'),
         url('../fonts/bliss-medium/bliss-medium.svg#blissregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


@mixin bliss($fontName) {
    @if $fontName == "light" {
        font-family: 'bliss-light', sans-serif;
    } @elseif $fontName == "medium" {
        font-family: 'bliss-medium', sans-serif;
    }
    font-weight: normal;
    font-style: normal;
}