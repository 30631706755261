/* ~~~~~~~~~~ Homepage ~~~~~~~~~~ */

.injecting-expertise,
.spare-parts {
    h2 {
        margin-top: 50px !important;

        @media (max-width: 991px) {
            margin-top: 0 !important;
        }
    }

	p + p {
		font-size: 16px;
		line-height: 21px;
	}
}


.imm-offer-the-following {
  	text-align: center;
  	background: url('../images/bgd_grid.jpg') repeat;
    color: #fff;
    padding: 20px 0 70px;

	h2 {
    	color: #fff;
  	}

  	.circles-wrapper {
  		.circle {
  			border: 3px solid #fff;
  			@extend %single-transition;

            @media (max-width: 767px) {
                width: 260px;
                height: 260px;
            }

  			h3 {
  				@extend h2;
  			}

			&.first-circle {
				margin: 60px 0 0 60px;

                @media (max-width: 767px) {
                    margin: 50px auto 0;
                }
			}

			&.second-circle {
				margin: 285px 0 0 260px;

                @media (max-width: 1199px) {
                    margin: 285px 0 0 160px;
                }

                @media (max-width: 991px) {
                    margin: 445px 0 0 160px;
                }

                @media (max-width: 767px) {
                    margin: 330px auto 0;
                }
			}
  		}

  		> img {
  			position: absolute;

            @media (max-width: 767px) {
                display: none;
            }
  		}

  		.first-line {
          	left: 390px;
          	top: 400px;
            -webkit-transform: rotate(25deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
                -ms-transform: rotate(25deg); /* IE 9 */
                    transform: rotate(25deg);

            @media (max-width: 1199px) {
                left: 345px;
                top: 440px;
                -webkit-transform: rotate(35deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
                    -ms-transform: rotate(35deg); /* IE 9 */
                        transform: rotate(35deg);
            }

            @media (max-width: 991px) {
                left: 280px;
                top: 510px;
                -webkit-transform: rotate(50deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
                    -ms-transform: rotate(50deg); /* IE 9 */
                        transform: rotate(50deg);
            }
  		}

  		.second-line {
          	left: 570px;
          	top: 750px;
            -webkit-transform: rotate(-40deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
                -ms-transform: rotate(-40deg); /* IE 9 */
                    transform: rotate(-40deg);

            @media (max-width: 1199px) {
                left: 455px;
                top: 760px;
                -webkit-transform: rotate(-45deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
                    -ms-transform: rotate(-45deg); /* IE 9 */
                        transform: rotate(-45deg);
            }

            @media (max-width: 991px) {
                left: 370px;
                top: 835px;
                -webkit-transform: rotate(-60deg); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
                    -ms-transform: rotate(-60deg); /* IE 9 */
                        transform: rotate(-60deg);
            }
  		}

  		.circle-slider {
  			height: 400px;
  			width: 400px;
  			border-radius: 50%;
  			overflow: hidden;
  			right: 90px;
  			margin-top: -90px;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            z-index: 5000;
            position: absolute;

            @media (max-width: 991px) {
                right: 60px;
                margin-top: 0;
            }

            @media (max-width: 767px) {
                width: 260px;
                height: 260px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                margin-top: 35px;
            }

  			h3 {
  				@extend h2;
  				position: absolute;
  				z-index: 1;
  				text-align: center;
  				width: 100%;
				a {
					color: #ffffff;
				}
  			}
			a {
				color: #fff;
			}

  			.carousel {
  				.carousel-indicators {
  					bottom: 50px;

					li {
						text-indent: 0;
						padding: 0;
						height: 12px;
						width: 12px;
						background: #fff;
						border: none;
						@extend %single-transition;

						&.active,
						&:hover {
							border: none;
							background: $primary-color;
							@extend %single-transition;
							height: 12px;
							width: 12px;
							margin-top: 0;
						}
					}
  				}

  				.carousel-inner {
  					.item {
  						text-align: center;
  						.background-cover {
  							height: 400px;
  							width: 400px;
  							position: relative;

                            @media (max-width: 767px) {
                                height: 260px;
                                width: 260px;
                            }

  							.caption {
  								position: absolute;
  								bottom: 100px;
  								display: block;
  								width: 100%;
  								font-size: 20px;
  								line-height: 26px;
  							}
  						}
  					}
  				}

  				.carousel-controls {
  					.carousel-services-control {
  						position: absolute;
  						top: 50%;
  						margin-top: -100px;
      					padding: 85px 10px;
      					width: 200px;
      					height: 200px;
      					border-radius: 50%;

		  				&:hover,
		  				&:focus {
		  					background: rgba(164, 187, 49, 0.7);
		  				}

  						span {
  							color: #fff;
  							font-size: 24px;
  						}

  						&.left {
      						left: -160px;
      						text-align: right;
  						}

  						&.right {
      						right: -160px;
      						text-align: left;
  						}
  					}
  				}
  			}
  		}
  	}
}

.testimonials {
	padding-top: 0;

    @media (max-width: 767px) {
        margin-top: 25px;
    }

	h2:first-child {
		margin-top: 0;
	}
}
