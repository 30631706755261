/* ~~~~~~~~~~ Html ~~~~~~~~~~ */

html {
    // position: relative;
    // min-height: 100%;
}

html,
body {
    width: 100% !important;
    height: 100% !important;
}


/* ~~~~~~~~~~ Body ~~~~~~~~~~ */

body {
    @include bliss('light');
    color: $secondary-color;
    font-size: 18px;
    line-height: 24px;

    @media (max-width: 1199px) {
        font-size: 17px;
        line-height: 23px;
    }

    @media (max-width: 991px) {
        font-size: 16px;
        line-height: 22px;
    }
}

/* ~~~~~~~~~~ Headlines ~~~~~~~~~~ */

h1 {
    @include bliss('medium');
    font-size: 45px;
    line-height: 56px;

    @media (max-width: 1199px) {
        font-size: 42px;
        line-height: 52px;
    }

    @media (max-width: 991px) {
        font-size: 38px;
        line-height: 48px;
    }

    @media (max-width: 767px) {
        font-size: 32px;
        line-height: 40px;
    }
}

h2 {
    @include bliss('medium');
    margin: 50px 0 30px;
    color: $primary-color;
    font-size: 30px;
    line-height: 38px;

    @media (max-width: 1199px) {
        font-size: 28px;
        line-height: 36px;
        margin: 40px 0 25px;
    }

    @media (max-width: 991px) {
        font-size: 26px;
        line-height: 34px;
        margin: 35px 0 20px;
    }

    @media (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
        margin: 25px 0 15px;
    }
}

h3 {
    font-size: 24px;
    line-height: 30px;
    margin: 20px 0 10px;
    color: $primary-color;

    @media (max-width: 1199px) {
        font-size: 22px;
        line-height: 28px;
    }

    @media (max-width: 991px) {
        font-size: 20px;
        line-height: 26px;
        margin: 15px 0 10px;
    }

    @media (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
        margin: 10px 0 5px;
    }
}

h4 {
    font-size: 21px;
    line-height: 27px;
    margin: 20px 0 10px;

    @media (max-width: 1199px) {
        font-size: 20px;
        line-height: 26px;
    }

    @media (max-width: 991px) {
        font-size: 18px;
        line-height: 24px;
    }

    @media (max-width: 767px) {
        font-size: 17px;
        line-height: 23px;
    }
}


/* ~~~~~~~~~~ Paragraphs ~~~~~~~~~~ */

p {
    margin-top: 25px;

    @media (max-width: 991px) {
        margin-top: 20px;
    }

    @media (max-width: 767px) {
        margin-top: 15px;
    }

    b,
    strong {
        @include bliss('medium');
    }

    a {
        color: $primary-color;
        border-bottom: 1px dotted transparent;

        &:hover,
        &:focus {
            border-color: rgba(255,255,255,0.5);;
        }
    }
}


/* ~~~~~~~~~~ ul, ol lists ~~~~~~~~~~ */

ul {
    list-style-type: disc;
    list-style-position: inside;
    list-style-image: url('../images/icon_list-image.png');
}

ol {
    list-style-type: decimal;
    list-style-position: inside;
}

ul,
ol {
    margin-top: 20px;

    ul {
        list-style-type: circle;
        list-style-position: inside;
        margin-left: 15px;
    }

    ol {
        list-style-type: lower-latin;
        list-style-position: inside;
        margin-left: 15px;
    }

    li {
        @extend p;
        margin-top: 5px;
        text-indent: -1em;
        padding-left: 1em;
    }
}


/* ~~~~~~~~~~ Images ~~~~~~~~~~ */

img {
    // width: 100%;
    // height: auto;
    margin-top: 25px;

    @media (max-width: 991px) {
        margin-top: 20px;
    }

    @media (max-width: 767px) {
        margin-top: 15px;
    }

    &.alignleft {
        float: left;
        width: auto;
        margin: 0 25px 15px 0;

        @media (max-width: 479px) {
            width: 100%;
        }
    }

     &.alignright {
        float: right;
        width: auto;
        margin: 0 0 15px 25px;

        @media (max-width: 479px) {
            width: 100%;
        }
    }
}

/* ~~~~~~~~~~ Sections ~~~~~~~~~~ */

section {
    overflow-x: hidden;

    &.section-paddings {
        padding: 75px 0;

        @media (max-width: 1199px) {
            padding: 50px 0;
        }

        @media (max-width: 991px) {
            padding: 35px 0;
        }

        @media (max-width: 767px) {
            padding: 25px 0;
        }

        h1,
        h2,
        h3,
        h4 {
            &:first-of-type {
                margin: 0;
            }
        }
    }
}


/* ~~~~~~~~~~ Buttons ~~~~~~~~~~ */

.btn {
    margin-top: 25px;
}

.btn-imm {
    border-radius: 12px;
    padding: 6px 25px;
    background-color: transparent;
    transition: 0.3s ease-out all;

    &.btn-imm-primary {
        color: $primary-color;
        border: 2px solid $primary-color;

        &:hover,
        &:focus {
            color: #fff;
            background-color: $primary-color;
        }
    }

    &.btn-imm-secondary {
        color: #fff;
        border: 2px solid #fff;

        &:hover,
        &:focus {
            color: $primary-color;
            background-color: #fff;
        }
    }
}

.btn-imm-well {
    color: #fff;
    font-size: 22px;
    background-color: $primary-color;
    border: none;
    border-radius: 2px;
    padding: 10px 20px;

    @media (max-width: 1199px) {
        font-size: 20px;
    }

    @media (max-width: 991px) {
        font-size: 18px;
    }

    @media (max-width: 767px) {
        font-size: 16px;
    }

    &:hover,
    &:focus {
        color: #fff;
        background-color: #80941B;
    }
}

.btn-download-spec {
    font-size: 18px;
    color: #fff;
    background-color: $secondary-color;
    border-radius: 2px;
    width: 100%;
    padding: 20px;
    transition: 0.3s ease-out all;

    &:hover,
    &:focus {
        background-color: #2C2B2D;
        color: #fff;
    }
}


/* ~~~~~~~~~~ Jumbotrons ~~~~~~~~~~ */

.jumbotron {
    padding: 275px 0 150px;
    margin: 0;
    @extend .background-cover;
    height: initial;
    overflow: hidden;

    @media (max-width: 1199px) {
        padding: 250px 0 125px;
    }

    @media (max-width: 991px) {
        padding: 200px 0 75px;
    }

    h1 {
        @extend h1;
        color: $primary-color;

        span {
            color: #414042;
        }
    }

    .banner-line {
        img {
            position: relative;
            left: -250px;
            margin: 10px 0 0;

            @media (max-width: 1199px) {
                left: -275px;
            }

            @media (max-width: 991px) {
                left: -300px;
            }

            @media (max-width: 767px) {
                left: -350px;
            }
        }
    }

    &.jumbotron-home {
        background-image: url("../images/home-banner.jpg");
    }

    &.jumbotron-services {
        background-image: url("../images/machines-banner.jpg");
    }

    &.jumbotron-machine {
        background-image: url("../images/white-machines-banner.jpg");
    }

    &.jumbotron-screws-and-barrels{
        background-image: url("../images/screws-banner.jpg");
    }
}

.no-jumbotron {
    background-color: #f1f1f2;
    padding: 200px 0 180px;

    @media (max-width: 1199px) {
        padding: 200px 0 140px;
    }

    @media (max-width: 991px) {
        padding: 175px 0 130px;
    }

    @media (max-width: 767px) {
        padding: 150px 0 50px;
    }

    h1 {
        color: $primary-color;
    }

    .relative-image-right {
        height: 0px;

        img {
            position: absolute;

            @media (max-width: 767px) {
                position: static;
            }
        }

        @media (max-width: 767px) {
            margin-top: 50px;
            height: auto;
        }

        &__no-margin {
            margin: 0 !important;
        }
    }

    .banner-line img {
        position: relative;
        left: -150px;
        top: 50px;

        @media (max-width: 767px) {
            top: 15px;
        }
    }
}


/* ~~~~~~~~~~ Relative images ~~~~~~~~~~ */

.relative-image-right,
.relative-image-left {
    position: relative;

    @media (max-width: 1199px) {
        margin-top: 50px;
    }

    @media (max-width: 991px) {
        margin-top: 35px;
    }

    img {
        margin: 0;
    }
}

.relative-image-right {
    left: 100px;

    @media (max-width: 1199px) {
        left: 50px;
    }

    @media (max-width: 991px) {
        left: 0;
    }

    &__no-margin {
        margin: 0 !important;
    }
}

.relative-image-left {
    right: 100px;

    @media (max-width: 1199px) {
        right: 50px;
    }

    @media (max-width: 991px) {
        right: 0;
    }
}


/* ~~~~~~~~~~ Figures ~~~~~~~~~~ */

.circle {
    width: 315px;
    height: 315px;
    border-radius: 50%;
    padding: 0 30px;

    h2,
    h3,
    h4 {
        margin: 0;
    }

    .overlay-content {
        display: none;
    }

    &:hover,
    &:focus {
        border-color: $primary-color !important;
        background: $primary-color;
        @extend %single-transition;

        h3 {
            color: #fff;
        }

        .overlay-content {
            display: block;
        }
    }
}


/* ~~~~~~~~~~ Wavy lines ~~~~~~~~~~ */

.holder {
    /* Clip edges, as some of the lines don't terminate nicely. */
    overflow: hidden;
    position: relative;
    width: 600px;
    height: 6px;
    margin-left: auto;
    margin-right: auto;

    .line {
        position: absolute;
        width: 600px;
        /* Height set to clip correctly basically background-size/2 + line width */
        height: 26px;
        background-size: 15px 15px;
    }
}

.first-style-line {
    background: linear-gradient(45deg, transparent, transparent 49%, #444444 55%, transparent 51%);
}

.second-style-line {
    background: linear-gradient(-45deg, transparent, transparent 49%, #444444 55%, transparent 51%);
}


/* ~~~~~~~~~~ Blockquote ~~~~~~~~~~ */

blockquote {
    font-size: 27px;
    line-height: 36px;
    color: $primary-color;
    @include bliss('medium');
    margin-top: 25px;
    padding: 0 35px;
    position: relative;

    @media (max-width: 1199px) {
        font-size: 26px;
        line-height: 34px;
    }

    @media (max-width: 991px) {
        margin-top: 20px;
        font-size: 24px;
        line-height: 32px;
    }

    @media (max-width: 767px) {
        margin-top: 15px;
        font-size: 22px;
        line-height: 28px;
    }

    &:before,
    &:after {
        position: absolute;
        font-size: 60px;
        display: block;
    }

    &:before {
        content: "\201C";
        top: 10px;
        left: -5px;
    }

    &:after {
        content: "\201D";
        bottom: -10px;
        right: 5px;
    }

    a {
        color: #7E921A;
    }

    & + .position {
        display: block;
        color: $primary-color;
        @extend p;
        margin: 35px 0 0 35px;

        & + .name {
            display: block;
            color: $primary-color;
            @extend p;
            margin: 0 0 0 35px;
        }
    }
}


/* ~~~~~~~~~~ Overlay squeres ~~~~~~~~~~ */

.overlay-image-squeres,
.overlay-content-squeres {
    margin-top: 75px;

    @media (max-width: 1199px) {
        margin-top: 50px;
    }

    @media (max-width: 991px) {
        margin-top: 35px;
    }

    @media (max-width: 767px) {
        margin-top: 25px;
    }

    [class*=' col-'] {
        padding: 0;
    }

    .background-cover {
        height: 385px;
        display: block;

        @media (max-width: 1199px) {
            height: 315px;
        }

        @media (max-width: 991px) {
            height: 240px;
        }

        .overlay-content {
            opacity: 0;
            @extend %single-transition;
            background: rgba(164, 187, 49, 0.7);
            height: 100%;
            width: 100%;
            text-align: center;
            padding: 25px;

            h3 {
                @extend h2;
                margin: 0;
                color: #fff;
            }
        }

        &:hover,
        &:focus {
            .overlay-content {
                opacity: 1;
                @extend %single-transition;
            }
        }
    }
}

.overlay-content-squeres {
    padding: 0 15px;

    .background-cover {
        .overlay-content {
            opacity: 1;
        }

        &.active,
        &:hover,
        &:focus {
            .overlay-content {
                opacity: 0;
            }
        }

        &.active {
            pointer-events: none;
            cursor: default;
        }
    }
}


/* ~~~~~~~~~~ Sidebar ~~~~~~~~~~ */

.sidebar {
    .panel-group {
        .panel-default {
            border: none !important;
            margin-top: 20px;
            box-shadow: none;

            &:first-child {
                margin-top: 0;
            }

            .panel-heading {
                background: $primary-color;
                border-radius: 0;
                border: none !important;
                padding: 15px 15px 15px 0;
                position: relative;
                box-shadow: none;

                &:before {
                    position: absolute;
                    background: $primary-color;
                    top: 0;
                    bottom: 0;
                    width: 998em;
                    content: '';
                    display: block;
                    left: -998em;
                }
                &.selected-panel-geading {
                    background: #7E921A;
                    &:before {
                        background: #7E921A;
                    }
                }

                h4 {
                    a {
                        color: #fff;
                    }
                }
            }

            .panel-body {
                border: none !important;
                padding: 0;

                ul {
                    margin: 0;
                    list-style: none;

                    li {
                        text-indent: 0;
                        padding: 0;
                        margin-top: 15px;

                        a {
                            color: $secondary-color;
                            font-size: 16px;
                            line-height: 22px;

                            &:hover,
                            &:focus {
                                color: $primary-color;
                            }

                            @media (max-width: 1199px) {
                                font-size: 15px;
                                line-height: 21px;
                            }

                            @media (max-width: 991px) {
                                font-size: 13px;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}


/* ~~~~~~~~~~ Pages paddings ~~~~~~~~~~ */

.template-single-machine {
    padding: 75px 0;
    overflow-x: hidden;

    @media (max-width: 1199px) {
        padding: 50px 0;
    }

    @media (max-width: 991px) {
        padding: 35px 0;
    }

    @media (max-width: 767px) {
        padding: 25px 0;
    }
}

.template-about-testimonials,
.template-single-service-subpage,
.template-single-service,
.template-machines,
.template-services,
.template-remote-assistance {
    padding-bottom: 70px;
    overflow-x: hidden;

    @media (max-width: 1199px) {
        padding-bottom: 50px;
    }

    @media (max-width: 991px) {
        padding-bottom: 35px;
    }

    @media (max-width: 767px) {
        padding-bottom: 25px;
    }
}

.template-machines,
.template-services {
    > section:first-child {
        background: #f1f2f2;

        h2 {
            margin-top: 0;
        }
    }
}


/* ~~~~~~~~~~ Key features ~~~~~~~~~~ */

.key-features {
    background: url('../images/bgd_grid.jpg');
    text-align: center;

    h2 {
        margin: 0 0 35px;
        color: #fff;

        @media (max-width: 1199px) {
            margin-bottom: 20px;
        }

        @media (max-width: 991px) {
            margin-bottom: 15px;
        }

        @media (max-width: 767px) {
            margin-bottom: 0;
        }
    }

    .circle {
        border: 3px solid $primary-color;
        margin: 40px auto 0;
        padding: 0 25px;

        @media (max-width: 1199px) {
            width: 275px;
            height: 275px;
            margin-top: 30px;
        }

        @media (max-width: 991px) {
            width: 230px;
            height: 230px;
            margin-top: 20px;
        }

        @media (max-width: 767px) {
            width: 260px;
            height: 260px;
            margin-top: 20px;
        }

        h3 {
            @extend h2;
            color: #fff;
        }

        p {
            color: #fff;
        }
    }
}
