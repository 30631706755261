/* ~~~~~~~~~~ Single machine ~~~~~~~~~~ */

.template-single-machine {
    h2,
    h3,
    p,
    .panel-group .panel-default .panel-heading .panel-title,
    .product-gallery img {
        margin: 0;
    }

    h2 {
        @media (max-width: 767px) {
            margin-top: 35px !important;
        }
    }

    h4 {
        &:first-of-type {
            margin-top: 50px;

            @media (max-width: 767px) {
                margin-top: 35px;
            }
        }
    }

    .product-gallery {
        @media (max-width: 991px) {
            margin-top: 50px;
        }

        @media (max-width: 767px) {
            margin-top: 35px;
        }

        .small-images-wrapper {
            padding: 0 7.5px;

            [class*='col-'] {
                padding: 0 7.5px;
            }

            img {
                margin-top: 15px;
            }
        }
    }

    .product-specification {
        margin-top: 25px;

        form {
            input[type=email] {
                background: #f1f1f1;
                width: 100%;
                border: 1px solid #e9e9e9;
                padding: 20px;
            }

            input[type=submit] {
                margin-top: 10px;
                border: none;
            }
        }
    }
}