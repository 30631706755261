/* ~~~~~~~~~~ Single service ~~~~~~~~~~ */

.template-single-service {
    .carousel {
        overflow: hidden;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        z-index: 1;

        @media (max-width: 767px) {
            margin-top: 50px;
        }

        .carousel-indicators {
            li {
                text-indent: 0;
                padding: 0;
                height: 12px;
                width: 12px;
                background: #fff;
                border: none;
                @extend %single-transition;

                &.active,
                &:hover {
                    border: none;
                    background: $primary-color;
                    @extend %single-transition;
                    height: 12px;
                    width: 12px;
                    margin-top: 0;
                }
            }
        }

        .carousel-inner {
            .item {
                .background-cover {
                    height: 400px;

                    @media (max-width: 1199px) {
                        height: 350px;
                    }

                    @media (max-width: 991px) {
                        height: 300px;
                    }

                    @media (max-width: 767px) {
                        height: 250px;  
                    }
                }
            }
        }

        .carousel-controls {
            .carousel-services-control {
                position: absolute;
                top: 50%;
                margin-top: -100px;
                padding: 85px 10px;
                width: 200px;
                height: 200px;
                border-radius: 50%;

                &:hover,
                &:focus {
                    background: rgba(164, 187, 49, 0.7);
                }

                span {
                    color: #fff;
                    font-size: 24px;
                }

                &.left {
                    left: -160px;
                    text-align: right;
                }

                &.right {
                    right: -160px;
                    text-align: left;
                }
            }
        }
    }
}
