.subscribe-modal-left-side {
  padding-right: 0;
}

.subscribe-modal-right-side {
  padding-left: 0;
}

.modal-left.flexbox-styles,
.modal-right.flexbox-styles {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24em;
}

.modal-left.flexbox-styles .modal-content-left {
  max-width: 80%;
}

.modal-right.flexbox-styles .modal-content-right {
  max-width: 100%;
}

.modal-left {
  background-color: #a4bb31;
  border-radius: 6px 0 0 6px;
  text-align: center;
}

.modal-right {
  color: #414042;
  p {
    text-align: center;
  }

  form {
    margin-top: 10px;
  }

  input {
    margin-top: 5px;
  }

  .form-group {
    // margin-bottom: -5px;
    .form-control {
      height: 45px;
      font-size: 17px;
      border-radius: 2px;
    }
  }
}

.btn-dark {
  background-color: #414042;
  border-radius: 2px;
  color: #ffffff;
  width: 100%;
  height: 45px;
  font-size: 17px;
  margin-top: 10px;
  @extend %single-transition;

  &:hover,
  &:focus {
    background-color: #000 !important;
    color: #fff !important;
    @extend %single-transition;
  }

}

@media (max-width: 767px) {
  .subscribe-modal-left-side {
    display: none;
  }
  .subscribe-modal-right-side {
    padding-left: 15px !important;
  }
}