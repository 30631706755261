#get-in-touch {
    color: #eeeeee;
    background-color: $secondary-color;
    margin-top: 150px;

    @media (max-width: 1199px) {
        margin-top: 170px;
    }

    @media (max-width: 991px) {
        margin-top: 190px;
    }

    .large-drop {
        margin: -200px 0 175px;
    }

    .contact-column,
    .address-column {
        @media (max-width: 991px) {
            margin-top: 50px;
        }
    }

    .contact-column {
        margin-top: 70px;

        @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 14px;
        }

        @media (max-width: 479px) {
            text-align: center;
        }

        img {
            @media (max-width: 991px) {
                margin-top: 0;
            }
        }

        .contact-details {
            margin-top: 35px;
            list-style: none;

            li {
                padding: 0;
                text-indent: 0;
                margin: 0;

                a {
                    border: none;

                    img {
                        margin-right: 20px;
                    }

                    &:hover,
                    &:focus {
                        color: $primary-color;
                    }
                }
            }
        }

        a {
            &:hover,
            &:focus {
                cursor: pointer;
            }
        }
    }

    .contact-form-wrapper {
        background-color: $primary-color;
        border-radius: 10px;
        padding: 30px;
        margin-top: -150px;

        @media (max-width: 767px) {
            padding: 30px 15px;
        }

        h3,
        p {
            text-align: center;
        }

        h3 {
            color: #fff;
        }

        input,
        textarea,
        select {
            font-size: 18px;
            line-height: 24px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            border: 1px solid transparent;
            padding: 15px 20px;
            color: rgba(255, 255, 255, 0.7);
            display: block;
            margin: 20px 0;
            width: 100%;
            height: auto;
            box-shadow: none;

            @media (max-width: 1199px) {
                font-size: 17px;
                line-height: 23px;
            }

            @media (max-width: 767px) {
                font-size: 16px;
                line-height: 22px;
            }

            &:hover,
            &:focus {
                border: 1px solid #fff;
            }

            &::-webkit-input-placeholder {
               color: rgba(255, 255, 255, 0.7);
            }

            &:-moz-placeholder {
               color: rgba(255, 255, 255, 0.7);
            }

            &::-moz-placeholder {
               color: rgba(255, 255, 255, 0.7);
            }

            &:-ms-input-placeholder {
               color: rgba(255, 255, 255, 0.7);
            }
        }

        input[type=submit] {
            width: auto;
            background: transparent;
            border: 2px solid #fff;
            font-size: 15px;
            color: #fff;

            &:hover,
            &:focus {
                border: 2px solid #fff;
                background: #fff;
                color: $primary-color;
            }
        }

        .btn-imm {
            padding: 6px 50px;
            margin: 0px auto;
        }

        .form-group {
            &:last-child {
                margin: 0;
            }
        }

        img {
            width: auto;
            margin: 0;
        }
    }

    .additional-navigation-wrapper {
        text-align: center;
        margin: 25px 0 75px;

        @media (max-width: 991px) {
            margin: 25px 0;
        }

        @media (max-width: 479xp) {
            text-align: center;
        }

        .additional-navigation {
            display: inline-block;
            margin: 0;
            padding: 0;
            /* For IE, the outcast */
            zoom:1;
            *display: inline;
            list-style: none;

            li {
                float: left;
                padding: 0 10px;
                text-indent: 0;
                border-right: 1px solid #fff;

                &:last-child {
                    border: none;
                }

                a {
                    font-size: 12px;
                    line-height: 17px;

                    &:hover,
                    &:focus {
                        color: #fff;
                    }
                }
            }
        }
    }

    .address-column {
        margin: 70px 0 0 40px;

        @media (max-width: 991px) {
            margin: 70px 0 0;
        }

        @media (max-width: 479px) {
            text-align: center;
        }
    }

    a {
        color: #eee;
    }
}