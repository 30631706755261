/* ~~~~~~~~~~ Centered columns ~~~~~~~~~~ */

.row-centered {
    text-align:center;
}
.col-centered {
    display:inline-block;
    float:none;
    text-align:left;
    margin-right:-4px;
}
.col-fixed {
    width:320px;
}
.col-min {
    min-width:320px;
}
.col-max {
    max-width:320px;
}