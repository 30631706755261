/* ~~~~~~~~~~ Machines ~~~~~~~~~~ */

.template-machines {
    .total-solutions {
        text-align: center;
        background: #f1f2f2;
        border-radius: 5px;
        border: 1px solid #e3e3e3;
        padding: 25px 25px 40px;
        margin-top: 75px;

        @media (max-width: 1199px) {
            margin-top: 50px;
        }

        @media (max-width: 991px) {
            margin-top: 35px;
        }

        @media (max-width: 767px) {
            margin-top: 25px;
        }

        h2 {
            margin-top: 0;
        }
    }
}