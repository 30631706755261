/* ~~~~~~~~~~ Sidebar navigation ~~~~~~~~~~ */

.sidebar {
    .sidebar-navigation {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            background: $primary-color;
            text-indent: 0;
            padding: 0;
            position: relative;
            margin-bottom: 15px;
            @extend %single-transition;

            &:last-child {
                margin: 0;
            }

            &:before {
                content: '';
                display: block;
                background: inherit;
                width: 998em;
                top: 0;
                bottom: 0;
                right: 0;
                position: absolute;
                z-index: -1;
            }

            &:hover,
            &:focus,
            &.active {
                background: #7f9025;
                @extend %single-transition;
            }

            a {
                color: #fff;
                padding: 15px 0;
                display: block;
                font-size: 16px;
                line-height: 22px;
                border: none;
            }
        }
    }
}
