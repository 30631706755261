/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Bootstrap ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    @import "../../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Base ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /* ~~~~~~~~~~ Fonts ~~~~~~~~~~ */

    @import "base/fonts";


    /* ~~~~~~~~~~ Reset ~~~~~~~~~~ */

    @import "base/reset";


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Helpers ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /* ~~~~~~~~~~ Variables ~~~~~~~~~~ */

    @import "helpers/variables";


    /* ~~~~~~~~~~ Repaters ~~~~~~~~~~ */

    @import "helpers/repeaters";


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Components ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /* ~~~~~~~~~~ Bootstrap breakpoint MS ~~~~~~~~~~ */

    @import "components/bootstrap-breakpoint-ms";


    /* ~~~~~~~~~~ Bootstrap centered columns ~~~~~~~~~~ */

    @import "components/bootstrap-centered-columns";


    /* ~~~~~~~~~~ Bootstrap height adjust columns ~~~~~~~~~~ */

    @import "components/bootstrap-height-adjust-columns";


    /* ~~~~~~~~~~ Bootstrap mobile navigation on tablets ~~~~~~~~~~ */

    @import "components/bootstrap-mobile-navigation-on-tablets";


    /* ~~~~~~~~~~ WP admin bar ~~~~~~~~~~ */

    @import "components/wp-admin-bar";



/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Layout ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /* ~~~~~~~~~~ General ~~~~~~~~~~ */

    @import "layout/general";


    /* ~~~~~~~~~~ Header ~~~~~~~~~~ */

    @import "layout/header";


    /* ~~~~~~~~~~ Footer ~~~~~~~~~~ */

    @import "layout/footer";


    /* ~~~~~~~~~~ Modal ~~~~~~~~~~ */

    @import "layout/modal";


    /* ~~~~~~~~~~ Sidebar ~~~~~~~~~~ */

    @import "layout/sidebar";


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* ~~~~~~~~~~ Pages ~~~~~~~~~~ */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /* ~~~~~~~~~~ Homepage ~~~~~~~~~~ */

    @import "pages/homepage";


    /* ~~~~~~~~~~ About ~~~~~~~~~~ */
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

        /* ~~~~~~~~~~ Our company ~~~~~~~~~~ */

        @import "pages/about/our-company";


        /* ~~~~~~~~~~ Testimonials ~~~~~~~~~~ */

        @import "pages/about/testimonials";


    /* ~~~~~~~~~~ Machines ~~~~~~~~~~ */
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

        /* ~~~~~~~~~~ Machines ~~~~~~~~~~ */

        @import "pages/machines/machines";


        /* ~~~~~~~~~~ Single machine ~~~~~~~~~~ */

        @import "pages/machines/single-machine";


    /* ~~~~~~~~~~ Services ~~~~~~~~~~ */
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

        /* ~~~~~~~~~~ Single service ~~~~~~~~~~ */

        @import "pages/services/single-service";





